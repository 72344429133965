import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userInfo from "../reducers/userInfoReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import uploadFiles from "../reducers/uploadResumesReducer";
import totalFiles from "../reducers/totalResumesCountReducer";
import uploadProgress from "../reducers/uploadProgressStatusReducer";
import resumesList from "../reducers/resumesPageListReducer";
import contryInfo from "../reducers/countryLocationReducer";
import stateInfo from "../reducers/stateLocationReducer";
import cityInfo from "../reducers/cityLocationReducer";
import industryInfo from "../reducers/industryReducer";
import categoryInfo from "../reducers/categoryReducer";
import rolesInfo from "../reducers/rolesReducer";
import educationInfo from "../reducers/educationDegreeReducer";
import specializationInfo from "../reducers/specializationReducer";
import skillInfo from "../reducers/getSkillsReducer";
import getSubSkillInfo from "../reducers/getSubSkillReducer";
import createJdInfo from "../reducers/createJdReducer";
import jobsListInfo from "../reducers/jobsPageListreducers";
import currencyListInfo from "../reducers/currencyReducer";

const persistConfigLogin = {
  key: "userInfo",
  storage,
  blacklist: ["extras"],
};

const uploadResumesConfig = {
  key: "files",
  storage,
  blacklist: ["extras"],
};

const totalResumeCountConfig = {
  key: "count",
  storage,
  blacklist: ["extras"],
};

const UploadProgressStatus = {
  key: "uploadStatus",
  storage,
  blacklist: ["extras"],
};

const getResumesList = {
  key: "getResumesData",
  storage,
  blacklist: ["extras"],
};

const getCountryList = {
  key: "getCountryData",
  storage,
  blacklist: ["extras"],
};

const getStatesList = {
  key: "getStateData",
  storage,
  blacklist: ["extras"],
};

const getCityList = {
  key: "getCityData",
  storage,
  blacklist: ["extras"],
};

const getIndustryList = {
  key: "getIndustryData",
  storage,
  blacklist: ["extras"],
};

const getCategoryList = {
  key: "getCategoryData",
  storage,
  blacklist: ["extras"],
};

const getRolesList = {
  key: "getRolesData",
  storage,
  blacklist: ["extras"],
};

const getEducationList = {
  key: "getEducationData",
  storage,
  blacklist: ["extras"],
};

const getSpecializationList = {
  key: "getSpecializationData",
  storage,
  blacklist: ["extras"],
};

const getSkillList = {
  key: "getSkillData",
  storage,
  blacklist: ["extras"],
};
const getSubSkillList = {
  key: "getSubSkillInfo",
  storage,
  blacklist: ["extras"],
};

const createJdDataStatus = {
  key: "createJdData",
  storage,
  blacklist: ["extras"],
};
const getjobsPageList = {
  key: "getJobsPageList",
  storage,
  blacklist: ["extras"],
};
const getCurrencyList = {
  key: "getCurrencyList",
  storage,
  blacklist: ["extras"],
};

const rootReducer = combineReducers({
  loginInfo: persistReducer(persistConfigLogin, userInfo),
  resumesInfo: persistReducer(uploadResumesConfig, uploadFiles),
  totalResumesInfo: persistReducer(totalResumeCountConfig, totalFiles),
  uploadProgressStatus: persistReducer(UploadProgressStatus, uploadProgress),
  getAllResumeslist: persistReducer(getResumesList, resumesList),
  getAllCountryList: persistReducer(getCountryList, contryInfo),
  getAllStatesList: persistReducer(getStatesList, stateInfo),
  getAllCityList: persistReducer(getCityList, cityInfo),
  getAllIndustryList: persistReducer(getIndustryList, industryInfo),
  getAllCategoryList: persistReducer(getCategoryList, categoryInfo),
  getAllRolesList: persistReducer(getRolesList, rolesInfo),
  getAllEducationList: persistReducer(getEducationList, educationInfo),
  getAllSpecializationList: persistReducer(
    getSpecializationList,
    specializationInfo
  ),
  getAllSkillsList: persistReducer(getSkillList, skillInfo),
  getAllSubSkillsList: persistReducer(getSubSkillList, getSubSkillInfo),
  createJdFormData: persistReducer(createJdDataStatus, createJdInfo),
  getAlljobsPageList: persistReducer(getjobsPageList, jobsListInfo),
  getAllCurrencyList: persistReducer(getCurrencyList, currencyListInfo),
  //add more reducer here
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

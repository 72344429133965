import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";
import { primaryColor } from "../Theme/variables";
import { Checkbox, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

interface AssistoTableProps {
  rows: any[] | undefined;
  columns: Column[];
  isPaginationRequired?: boolean;
  defaultMessage: string;
  headerCheckBoxNeeded?: boolean;
  headerFontSize: string;
  dataCheckBoxNeeded?: boolean;
  checkBoxDropdownNeeded?: boolean;
  checked?: boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#fff",
  color: primaryColor,
  fontWeight: 800,
  fontFamily: "Poppins",
}));

export const AssistoTable = (props: AssistoTableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10000);
  const [openDropdown, setOpenDropdown] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <TableContainer
        sx={{ overflowX: "auto", border: "none", borderRadius: "none" }}
      >
        <Table stickyHeader>
          <TableHead className="table-header">
            <TableRow sx={{ fontWeight: "500" }}>
              {props.headerCheckBoxNeeded && (
                <StyledTableCell
                  align={"center"}
                  style={{
                    minWidth: 10,
                    backgroundColor: "var(--color-lighter-grey)",
                  }}
                >
                  <Checkbox
                    color="primary"
                    checked={props.checked}
                    // onChange={onSelectAllClick}
                  />
                </StyledTableCell>
              )}
              {props.dataCheckBoxNeeded &&
                props.headerCheckBoxNeeded !== undefined &&
                !props.headerCheckBoxNeeded && (
                  <StyledTableCell
                    align={"center"}
                    style={{
                      minWidth: 10,
                      backgroundColor: "var(--color-lighter-grey)",
                    }}
                  ></StyledTableCell>
                )}
              {props.columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    fontSize: props.headerFontSize,
                    fontWeight: "600",
                    backgroundColor: "var(--color-lighter-grey)",
                  }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows !== undefined && props.rows.length > 0 ? (
              props.rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      className="table-hover"
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {props.dataCheckBoxNeeded && (
                        <TableCell align={"center"} style={{ minWidth: 10 }}>
                          <Checkbox
                            color="primary"
                            checked={props.checked}
                            // onChange={onSelectAllClick}
                          />
                        </TableCell>
                      )}
                      
                      {props.checkBoxDropdownNeeded? (
                      <TableCell align={"center"} style={{ minWidth: 10 }}>
                          <>
                          <Checkbox
                            color="primary"
                            checked
                            // onChange={onSelectAllClick}
                          />
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpenDropdown(!openDropdown)}
                          >
                            {openDropdown ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                          </>
                        </TableCell>
                        ) : null}
                      {props.columns.map((column) => {
                        const value = row[column.id];

                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value !== null &&
                            value !== undefined &&
                            value.length !== 0 ? (
                              <>
                                {Array.isArray(value)
                                  ? value.join(" , ")
                                  : value}
                              </>
                            ) : (
                              "No-Info"
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={6}
                  align="center"
                  style={{
                    padding: "30px",
                    fontSize: "var(--font-size-normal)",
                  }}
                >
                  {props.defaultMessage}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {props.isPaginationRequired && (
        <TablePagination
          sx={{ my: 2 }}
          className="table-container"
          rowsPerPageOptions={[10, 50, 100, 500]}
          component="div"
          count={props.rows !== undefined ? props.rows.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

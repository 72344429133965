import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { getJobsListPage } from "../thunks/jobsPageListthunk";

export interface IJobsList {
  status_message: string;
  data: IData;
}

export interface IData {
  jobs_details_list: IJobsDetailsList[];
  page: number;
  pageSize: number;
  total_jobs_count: number;
}

export interface IJobsDetailsList {
  jd_details: IJdDetails;
  jd_skills_details: IJdSkillsDetail[];
  matched_profiles: number;
}

export interface IJdSkillsDetail {
  skill: string;
  experience_required_for_skill: number;
  ismandatory: boolean;
  extras: Extras;
  jd_uuid: string;
  is_active: boolean;
  created_at: string;
}

export interface Extras {}

export interface IJdDetails {
  id: number;
  jd_uuid: string;
  posting_type: string;
  job_title: string;
  job_end_date: string;
  job_summary: string;
  job_description: string;
  industry: string;
  category: string;
  roles: string;
  minimum_experience: number;
  maximum_experience: number;
  certified_degree_name: string;
  specialization: string;
  currency: string;
  minimum_salary: number;
  maximum_salary: number;
  contract_joining_date: string;
  contract_duration: string;
  contract_billing_rate: string;
  walkin_city_name: string;
  walkin_start_date: string;
  walkin_end_date: string;
  walkin_start_time: string;
  walkin_end_time: string;
  vacancy: number;
  country: string;
  state: string;
  city: string;
  company_name: string;
  about_company: string;
  name: string;
  phone_number: string;
  email: string;
  apply_online_url: string;
  is_short_jd: boolean;
  template_name: string;
  is_template: boolean;
  status: string;
  created_at: string;
  created_by: string;
  updated_at: string;
  updated_by: string;
}

export interface IJobsListResponse {
  jobsData: IJobsList;
  loading: string;
}

const initialState: IJobsListResponse = {
  jobsData: {
    status_message: "",
    data: {
      jobs_details_list: [],
      page: 0,
      pageSize: 0,
      total_jobs_count: 0,
    },
  },
  loading: "",
};


export const jobsListSlice = createSlice({
  name: "jobsList",
  initialState,
  reducers: {
    setResumeList: (state, action: PayloadAction<IJobsList>) => {
      state.jobsData.status_message = action.payload.status_message;
      state.jobsData.data = action.payload.data;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getJobsListPage.pending, (state) => {
        state.loading = "Pending";
      })
      .addCase(getJobsListPage.fulfilled, (state, action: any) => {
        state.loading = "Succeded";
        state.jobsData = action.payload;
      })
      .addCase(getJobsListPage.rejected, (state, action: any) => {
        state.loading = "Failed";
      });
  },
});

export const { setResumeList } = jobsListSlice.actions;
export const GetCompleteJobsListData = (state: RootState) =>
  state.getAlljobsPageList.jobsData.data.jobs_details_list;

export const GetTotalJobsData = (state: RootState) =>
  state.getAlljobsPageList.jobsData.data.total_jobs_count;

export default jobsListSlice.reducer;

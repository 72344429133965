import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import DropDownitems, { IMenus } from "../../../widgets/DropDownItems";
import AssistoTextFiled from "../../../widgets/AssistoTextField";
import {
  alphaNumericWithSpaceOnlySpecialCharPattern,
  alphabetsWithFiveThousandChar,
  alphabetsWithTwoThousandChar,
  alphanumericWithSpaceAndDotChar,
  digitsOnly,
  experienceFieldWithDecimalChar,
  onlineUrl,
  onlyAlphabetsWithMaxFifteenChar,
  mobileNumberPattern,
  validEmailPattern,
  onlyNumbersAboveZeroAndMaxFourDigits,
  alphanumericSnakeCase,
} from "../../../utils/regExp";
import { useTranslation } from "react-i18next";
import { NAMESPACE } from "../../../utils/i18nUtils";
import { ThemeProvider } from "styled-components";
import theme from "../../../widgets/Theme/theme";
import AssistoDatePicker from "../../../widgets/AssistoDatePicker";
import BusinessCenterRoundedIcon from "@mui/icons-material/BusinessCenterRounded";
import AssistoCustomAddInputField from "../../../widgets/AssistoCustomAddInputField";
import SchoolIcon from "@mui/icons-material/School";
import PaidIcon from "@mui/icons-material/Paid";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { AssistoButton } from "../../../widgets/AssitoButton";
import DrawRoundedIcon from "@mui/icons-material/DrawRounded";
import ContactsIcon from "@mui/icons-material/Contacts";
import AssistantIcon from "@mui/icons-material/Assistant";
import AssistoTimePicker from "../../../widgets/AssistoTimePicker";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import dayjs, { Dayjs } from "dayjs";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { ILocationInfoResponse } from "../../../thunks/countryLocationThunk";
import { countryData } from "../../../reducers/countryLocationReducer";
import {
  IStateInfoResponse,
  StateLocationToken,
} from "../../../thunks/stateLocationThunk";
import { stateData } from "../../../reducers/stateLocationReducer";
import {
  CityLocationToken,
  ICityInfoResponse,
} from "../../../thunks/cityLocation";
import { cityData } from "../../../reducers/cityLocationReducer";
import { IIndustryData } from "../../../thunks/industryThunk";
import { industryData } from "../../../reducers/industryReducer";
import { ICategoryData } from "../../../thunks/categoryThunk";
import { categoryData } from "../../../reducers/categoryReducer";
import { IRolesData, RolesThunkToken } from "../../../thunks/rolesThunk";
import { RolesData } from "../../../reducers/rolesReducer";
import { certifiedDegreeData } from "../../../reducers/educationDegreeReducer";
import { IEducationData } from "../../../thunks/educationDegreeThunk";
import {
  ISpecializationData,
  SpecializationThunk,
} from "../../../thunks/specializationThunk";
import { SpecializationData } from "../../../reducers/specializationReducer";
import { subskillData } from "../../../reducers/getSubSkillReducer";
import { ISubSkillData } from "../../../thunks/getSubSkillThunk";
import { skillData } from "../../../reducers/getSkillsReducer";
import { ISkillData } from "../../../thunks/getSkillthunk";
import { currencyData } from "../../../reducers/currencyReducer";
import { ICurrencyResponse } from "../../../thunks/currencyThunk";
import { createJdThunk } from "../../../thunks/createJdThunk";
import {
  createJdApiLoading,
  resetCreateJdState,
} from "../../../reducers/createJdReducer";
import SnackBar, { Status, Variant } from "../../../widgets/Toaster";
import { alphabetsNumbersWithDotAndHashAndSpace } from "../../../utils/regExp";
import { CONTRACT, FULL_TIME, WALK_IN_DRIVE } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import { jobsPage } from "../../Routes/routes";
import { ButtonVarient } from "../../../utils/commonEnums";
import { Errors, Item } from "../../../utils/commonInterfaces";
import AssistoModal from "../../../widgets/Modal";
import { CSSProperties } from "styled-components";
import TextEditor from "../../../widgets/TextEditor";

const saveTemplateModalStyle: CSSProperties = {
  position: "absolute",
  transform: "translate(50%, -50%)",
  top: "50%",
  right: "50%",
  width: 800,
  maxHeight: 600,
  borderRadius: "8px",
  backgroundColor: "#fff",
  padding: 3,
};

export interface ISkills {
  skill: string;
  experience_required_for_skill: number;
  ismandatory: boolean;
  extras: Extras;
}

export interface Extras {}

interface IFields {
  job_posting_type: string;
  domain: string;
  jobTitleField: string;
  companyName: string | null;
  status: string;
  industry: IIndustryData | null;
  category: ICategoryData | null;
  roles: IRolesData | null;
  job_end_date: Dayjs | null;
  vacancy: string | null;
  notice_period: string;
  jobSummary: string | null;
  jobDescription: string | null;
  fromExpField: number;
  toExpField: number;
  certifiedDegree: IEducationData | null;
  specialization: ISpecializationData | null;
  minSalary: string;
  maxSalary: string;
  currency: ICurrencyResponse | null;
  name: string;
  phoneNo: number | null;
  email: string;
  aboutCompany: string;
  onlineUrl: string | null;
  venue: string | null;
  country: ILocationInfoResponse | null;
  state: IStateInfoResponse | null;
  city: ICityInfoResponse | null;
  selectedSkills: ISkillData | null;
  fromTiming: Dayjs | null;
  toTiming: Dayjs | null;
  fromWalkDate: Dayjs | null;
  toWalkDate: Dayjs | null;
  joiningDate: Dayjs | null;
  contractTimeperiod: string;
  billingRate: string | null;
  mustHaveSkill: string[];
  goodToHaveSkill: string[];
  jd_skills_details: ISkills[] | null;
  is_template: boolean;
  templateNameField: string;
}

function JobDescription() {
  const initialCreateJdState = {
    job_posting_type: FULL_TIME,
    domain: "india",
    jobTitleField: "",
    companyName: "",
    status: "Active",
    industry: null,
    category: null,
    roles: null,
    job_end_date: null,
    vacancy: null,
    notice_period: "",
    jobSummary: null,
    jobDescription: null,
    fromExpField: 0,
    toExpField: 0,
    certifiedDegree: null,
    specialization: null,
    minSalary: "",
    maxSalary: "",
    currency: null,
    name: "",
    phoneNo: null,
    email: "",
    aboutCompany: "",
    onlineUrl: null,
    venue: null,
    country: null,
    state: null,
    city: null,
    selectedSkills: null,
    fromTiming: null,
    toTiming: null,
    fromWalkDate: null,
    toWalkDate: null,
    joiningDate: null,
    contractTimeperiod: "",
    billingRate: "PER_YEAR",
    skillsItemData: [],
    subskillData: [],
    mustHaveSkill: [],
    goodToHaveSkill: [],
    jd_skills_details: [],
    selectedSkillData: [],
    is_template: false,
    templateNameField: "",
  };

  const initialErrorJdState = {
    jobTitleField: {
      mandatory: false,
      patternFail: false,
    },
    companyName: {
      patternFail: false,
    },
    vacancy: {
      patternFail: false,
    },
    fromExpField: {
      mandatory: false,
      patternFail: false,
      lessThan: false,
    },
    toExpField: {
      mandatory: false,
      patternFail: false,
      greaterThan: false,
    },
    certifiedDegree: {
      mandatory: false,
    },
    specialization: {
      mandatory: false,
    },
    mustHaveSkill: {
      mandatory: false,
      patternFail: false,
    },
    minSalary: {
      patternFail: false,
      minSalary: false,
    },
    maxSalary: {
      patternFail: false,
      maxSalary: false,
    },
    joiningDate: {
      mandatory: false,
    },
    currency: {
      mandatory: false,
    },
    country: {
      mandatory: false,
    },
    name: {
      mandatory: false,
      patternFail: false,
    },
    phoneNo: {
      patternFail: false,
    },
    email: {
      mandatory: false,
      patternFail: false,
    },
    onlineUrl: {
      patternFail: false,
    },
    billingRate: {
      mandatory: false,
      patternFail: false,
    },
    contractTimeperiod: {
      mandatory: false,
      patternFail: false,
    },
    venue: {
      mandatory: false,
      patternFail: false,
    },
    fromWalkDate: {
      mandatory: false,
    },
    toWalkDate: {
      mandatory: false,
    },
    fromTiming: {
      mandatory: false,
    },
    toTiming: {
      mandatory: false,
      patternFail: false,
    },
    aboutCompany: {
      patternFail: false,
    },
    jobSummary: {
      patternFail: false,
    },
    jobDescription: {
      patternFail: false,
    },
  templateNameField: {
      mandatory: false,
      patternFail: false,
    }
  };

  const BillingRateItems: IMenus[] = [
    {
      label: "Per Hour",
      value: "PER_HOUR",
    },
    {
      label: "Per Day",
      value: "PER_DAY",
    },
    {
      label: "Per Week",
      value: "PER_WEEK",
    },
    {
      label: "Per Year",
      value: "PER_YEAR",
    },
  ];

  const MenuItems: IMenus[] = [
    {
      label: "Full Time",
      value: FULL_TIME,
    },
    {
      label: "Contract",
      value: CONTRACT,
    },
    {
      label: "Walk In",
      value: WALK_IN_DRIVE,
    },
  ];

  const RegionItems: IMenus[] = [
    {
      label: "India",
      value: "india",
    },
  ];

  const StatusItems: IMenus[] = [
    {
      label: "Active",
      value: "Active",
    },
    {
      label: "Hold",
      value: "Hold",
    },
    {
      label: "Inactive",
      value: "Inactive",
    },
    {
      label: "Closed",
      value: "Closed",
    },
  ];

  const NoticePeriodItems: IMenus[] = [
    {
      label: "Immediate",
      value: "immediate",
    },
    {
      label: "Upto 15 days",
      value: "upto_15_days",
    },
    {
      label: "Upto 30 days",
      value: "upto_30_days",
    },
    {
      label: "Upto 45 days",
      value: "upto_45_days",
    },
    {
      label: "Upto 2 months",
      value: "upto_2_months",
    },
    {
      label: "Upto 3 months",
      value: "upto_3_months",
    },
    {
      label: "Upto 6 months",
      value: "upto_6_months",
    },
  ];

  const [createJdPage, setCreateJdPage] =
    useState<IFields>(initialCreateJdState);
  const [errors, setErrors] = useState<Errors>(initialErrorJdState);
  const [toaster, setToaster] = useState({
    open: false,
    message: "",
    status: Status.Success,
    variant: Variant.Filled,
  });
  const [selectedSkillData, setSelectedSkillData] = useState<ISkillData[]>();
  const [saveTemplate, setSaveTemplate] = useState<boolean>(false);
  const { t } = useTranslation(NAMESPACE.CREATE_JD);
  const dispatch: AppDispatch = useDispatch();
  const countryItems: ILocationInfoResponse[] = useSelector(countryData);
  const stateItems: IStateInfoResponse[] = useSelector(stateData);
  const cityItems: ICityInfoResponse[] = useSelector(cityData);
  const industryItemsData: IIndustryData[] = useSelector(industryData);
  const categoryItemsData: ICategoryData[] = useSelector(categoryData);
  const rolesItemsData: IRolesData[] = useSelector(RolesData);
  const degreeItemsData: IEducationData[] = useSelector(certifiedDegreeData);
  const specializationItemsData: ISpecializationData[] =
    useSelector(SpecializationData);

  const skillsItemData: ISkillData[] = useSelector(skillData);
  const subSkillsData: ISubSkillData[] = useSelector(subskillData);
  const currencyItemData: ICurrencyResponse[] = useSelector(currencyData);
  const createJdApiLoadingStatus = useSelector((state: RootState) =>
    createJdApiLoading(state)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (createJdApiLoadingStatus === "succeeded") {
      setCreateJdPage(initialCreateJdState);
      setErrors(initialErrorJdState);
      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.JOB_CREATED_SUCCESSFULLY"),
          status: Status.Success,
        };
      });
      dispatch(resetCreateJdState());
    }
    if (createJdApiLoadingStatus === "failed") {
      setToaster((toast) => {
        return {
          ...toast,
          open: true,
          message: t("CREATE_JD.FAILED_TO_CREATE_A_JD"),
          status: Status.Error,
        };
      });
    }
  }, [createJdApiLoadingStatus]);

  useEffect(() => {
    if (createJdPage.minSalary === "" && createJdPage.maxSalary === "") {
      setCreateJdPage((prev) => {
        return { ...prev, currency: null };
      });
      setErrors((prev) => {
        return {
          ...prev,
          currency: {
            mandatory: false,
          },
        };
      });
    }
  }, [createJdPage.minSalary, createJdPage.maxSalary]);

  useEffect(() => {
    const goodToHaveSkills = skillsItemData.filter(
      (skill: any) => !createJdPage.mustHaveSkill.includes(skill.skill)
    );
    setSelectedSkillData(goodToHaveSkills);
  }, [createJdPage.mustHaveSkill]);

  const handleFieldChange = (
    e:
      | SelectChangeEvent<string>
      | ChangeEvent<HTMLTextAreaElement>
      | ChangeEvent<HTMLInputElement>,
    id: string,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    const value = e.target.value;
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      [id]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        mandatory: isMandatory ? value === "" : false,
        patternFail: value !== "" ? !regex?.test(value) : false,
        greaterThan: Number(value) < createJdPage?.fromExpField,
        maxSalary:
          createJdPage.minSalary !== null
            ? Number(value) < Number(createJdPage?.minSalary)
            : false,
      },
    }));
  };

  const handleTextEditor = (
    content:string,
    id: string,
    isMandatory: boolean,
    regex?: RegExp
  ) => {
    setCreateJdPage((prevState)=>({
      ...prevState,
      [id]: content,
      }))

    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: {
        mandatory: isMandatory ? content === "" : false,
        patternFail: content !== "" ? !regex?.test(content) : false,
        greaterThan: Number(content) < createJdPage?.fromExpField,
        maxSalary:
          createJdPage.minSalary !== null
            ? Number(content) < Number(createJdPage?.minSalary)
            : false,
      },
    }));
  };

  const handleSelectChange = <T extends Item>(
    fieldId: string,
    e: SelectChangeEvent<string>,
    data: T[] | undefined,
    itemKey: string,
    dispatchAction?: any
  ) => {
    const selectedValue = e.target.value;
    let selectedItem: any = data?.find(
      (d: any) => d[itemKey] === selectedValue
    );
    if (selectedItem !== null && dispatchAction) {
      if (fieldId == "state") {
        dispatch(
          CityLocationToken({
            countryId: createJdPage.country?.id ?? "",
            stateId: selectedItem.id,
          })
        );
      } else {
        dispatch(dispatchAction(selectedItem?.id));
      }
    }
    setCreateJdPage((prevValue) => ({
      ...prevValue,
      [fieldId]: selectedItem,
    }));
  };

  const handleDatePickChange = (newDate: Dayjs | null, id: string) => {
    setCreateJdPage((select) => ({
      ...select,
      [id]: newDate,
    }));
  };

  const onTimePickChange = (newTime: Dayjs | null, id: string) => {
    if (!newTime) return;
    setCreateJdPage((select) => ({
      ...select,
      [id]: newTime,
    }));
    setErrors((prev) => {
      return {
        ...prev,
        toTiming: {
          mandatory: false,
          patternFail:
            id === "fromTiming" && createJdPage.toTiming !== null
              ? !newTime.isBefore(createJdPage.toTiming)
              : id === "toTiming" && createJdPage.fromTiming !== null
              ? !createJdPage.fromTiming?.isBefore(newTime)
              : false,
        },
      };
    });
  };

  const mandatoryType = (obj?: unknown, num?: any): boolean => {
    return obj !== undefined && obj !== null && obj !== "";
  };

  const patternFailType = (
    value: string | undefined | null | any,
    regex: RegExp | any,
    isMandatory?: boolean
  ): boolean => {
    if (isMandatory) {
      return regex.test(value);
    } else {
      return true;
    }
  };

  const handleCreateJdFormValidation = (): boolean => {
    return (
      mandatoryType(createJdPage.jobTitleField) &&
      patternFailType(
        createJdPage.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern,
        true
      ) 
      &&
      patternFailType(
        createJdPage.companyName,
        alphaNumericWithSpaceOnlySpecialCharPattern
      ) &&
      patternFailType(
        String(createJdPage.vacancy),
        onlyNumbersAboveZeroAndMaxFourDigits
      ) &&
      patternFailType(
        createJdPage.jobSummary,
        alphabetsWithTwoThousandChar,
        false
      ) &&
      patternFailType(
        createJdPage.jobDescription,
        alphabetsWithFiveThousandChar,
        false
      ) &&
      mandatoryType(createJdPage.fromExpField) &&
      (createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.fromExpField,
            experienceFieldWithDecimalChar
          )
        : false) &&
      mandatoryType(createJdPage.toExpField) &&
      (createJdPage.toExpField !== null && createJdPage.fromExpField !== null
        ? patternFailType(
            createJdPage.toExpField,
            experienceFieldWithDecimalChar
          ) || createJdPage.toExpField < createJdPage.fromExpField
        : false) &&
      (createJdPage.jobDescription !== "" &&
      createJdPage.jobDescription !== null
        ? patternFailType(
            createJdPage.jobDescription,
            alphabetsWithFiveThousandChar,
            false
          )
        : true) &&
      createJdPage.mustHaveSkill.length > 0 &&
      (createJdPage.minSalary !== null && createJdPage.maxSalary !== null
        ? patternFailType(createJdPage.minSalary, digitsOnly) ||
          createJdPage.minSalary > createJdPage.maxSalary
        : false) &&
      mandatoryType(createJdPage.certifiedDegree) &&
      mandatoryType(createJdPage.specialization) &&
      patternFailType(String(createJdPage.minSalary), digitsOnly) &&
      patternFailType(String(createJdPage.maxSalary), digitsOnly) &&
      mandatoryType(createJdPage.country) &&
      mandatoryType(createJdPage.name) &&
      patternFailType(
        createJdPage.name,
        onlyAlphabetsWithMaxFifteenChar,
        true
      ) &&
      patternFailType(String(createJdPage.phoneNo), mobileNumberPattern) &&
      mandatoryType(createJdPage.email) &&
      patternFailType(createJdPage.email, validEmailPattern, true) &&
      patternFailType(
        createJdPage.aboutCompany,
        alphabetsWithTwoThousandChar,
        false
      ) &&
      patternFailType(createJdPage.onlineUrl, onlineUrl) &&
      (createJdPage.job_posting_type === CONTRACT
        ? mandatoryType(createJdPage.contractTimeperiod)
        : true) &&
      (createJdPage.job_posting_type === CONTRACT
        ? createJdPage.contractTimeperiod !== ""
          ? patternFailType(
              createJdPage.contractTimeperiod,
              alphanumericWithSpaceAndDotChar,
              true
            )
          : true
        : true) &&
      (createJdPage.job_posting_type === CONTRACT
        ? mandatoryType(createJdPage.joiningDate)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.venue)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? createJdPage.venue !== null
          ? patternFailType(
              createJdPage.venue,
              alphabetsWithTwoThousandChar,
              true
            )
          : true
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.fromWalkDate)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.toWalkDate)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.fromTiming)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE
        ? mandatoryType(createJdPage.toTiming)
        : true) &&
      (createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.fromTiming !== null &&
      createJdPage.toTiming !== null
        ? createJdPage.fromTiming?.isBefore(createJdPage.toTiming)
        : true) &&
        (saveTemplate === true ? 
          (mandatoryType(createJdPage.templateNameField)
         &&
          patternFailType(
          createJdPage.templateNameField,
          alphanumericSnakeCase,
          true
        )) 
      : true )
    );
  };

  const handleCreateJdFormData = () => {
    const skillsData = createJdPage.mustHaveSkill
      .map((skill) => {
        return {
          skill: skill,
          ismandatory: true,
        };
      })
      .concat(
        createJdPage.goodToHaveSkill.map((skill) => {
          return {
            skill: skill,
            ismandatory: false,
          };
        })
      );

    const FormData = {
      jd_details: {
        posting_type: createJdPage.job_posting_type,
        job_title: createJdPage.jobTitleField,
        job_end_date: createJdPage.job_end_date?.format("YYYY-MM-DD"),
        job_summary: createJdPage.jobSummary,
        job_description: createJdPage.jobDescription,
        industry: createJdPage.industry?.id,
        category: createJdPage.category?.id,
        roles: createJdPage.roles?.id,
        minimum_experience: Number(createJdPage.fromExpField),
        maximum_experience: Number(createJdPage.toExpField),
        certified_degree_name: createJdPage.certifiedDegree?.id,
        specialization: createJdPage.specialization?.id,
        currency:
          createJdPage.minSalary === "" && createJdPage.maxSalary === ""
            ? null
            : createJdPage.currency?.cc,
        minimum_salary:
          createJdPage.minSalary !== "" ? Number(createJdPage.minSalary) : null,
        maximum_salary:
          createJdPage.maxSalary !== "" ? Number(createJdPage.maxSalary) : null,
        contract_joining_date: createJdPage.joiningDate?.format("YYYY-MM-DD"),
        contract_duration: createJdPage.contractTimeperiod,
        contract_billing_rate: createJdPage.billingRate,
        walkin_city_name: createJdPage.venue,
        walkin_start_date: createJdPage.fromWalkDate?.format("YYYY-MM-DD"),
        walkin_end_date: createJdPage.toWalkDate?.format("YYYY-MM-DD"),
        walkin_start_time: createJdPage.fromTiming?.format(
          "HH:mm:ss YYYY-MM-DD"
        ),
        walkin_end_time: createJdPage.toTiming?.format("HH:mm:ss YYYY-MM-DD"),
        vacancy: createJdPage.vacancy,
        country: createJdPage.country?.name,
        state: createJdPage.state?.name,
        city: createJdPage.city?.name,
        company_name:
          createJdPage.companyName === "" ? null : createJdPage.companyName,
        about_company: createJdPage.aboutCompany,
        name: createJdPage.name,
        phone_number: createJdPage.phoneNo,
        email: createJdPage.email,
        apply_online_url: createJdPage.onlineUrl,
        is_short_jd: false,
        status: createJdPage.status,
        is_template: saveTemplate,
        template_name: createJdPage.templateNameField !== "" ? createJdPage.templateNameField : null,
      },
      jd_skills_details: skillsData,
    };
    return FormData;
  };

  const handleCreateJdFormErrors = () => {
    if (createJdPage?.jobTitleField === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobTitleField: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (
      !patternFailType(
        createJdPage?.jobTitleField,
        alphaNumericWithSpaceOnlySpecialCharPattern
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobTitleField: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage?.companyName !== "" &&
      !patternFailType(
        createJdPage?.companyName,
        alphaNumericWithSpaceOnlySpecialCharPattern
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          companyName: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.vacancy !== null &&
      Number(createJdPage.vacancy) > 0 &&
      !patternFailType(
        createJdPage?.vacancy,
        onlyNumbersAboveZeroAndMaxFourDigits
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          vacancy: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.jobSummary !== null &&
      !patternFailType(createJdPage?.jobSummary, alphabetsWithTwoThousandChar)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobSummary: {
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.jobDescription !== null &&
      !patternFailType(
        createJdPage?.jobDescription,
        alphabetsWithTwoThousandChar
      )
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobDescription: {
            patternFail: true,
          },
        };
      });
    }

    if (createJdPage.fromExpField > createJdPage.toExpField) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          toExpField: {
            mandatory: false,
            patternFail: false,
            greaterThan: true,
          },
        };
      });
    }

    if (createJdPage?.mustHaveSkill.length === 0) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          mustHaveSkill: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          mustHaveSkill: {
            mandatory: false,
            patternFail: false,
          },
        };
      });
    }

    if (createJdPage?.certifiedDegree === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          certifiedDegree: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    }

    if (
      createJdPage.currency === null &&
      (createJdPage?.minSalary !== "" || createJdPage?.maxSalary !== "")
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          currency: {
            mandatory: true,
          },
        };
      });
    }

    if (createJdPage?.specialization === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          specialization: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === CONTRACT &&
      createJdPage?.joiningDate === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          joiningDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === CONTRACT &&
      createJdPage?.contractTimeperiod === ""
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          contractTimeperiod: {
            mandatory: true,
            patternFail: true,
          },
        };
      });
    } else if (
      createJdPage.job_posting_type === CONTRACT &&
      createJdPage?.contractTimeperiod !== "" &&
      !patternFailType(
        createJdPage?.contractTimeperiod,
        alphanumericWithSpaceAndDotChar
      )
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          contractTimeperiod: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      (createJdPage.job_posting_type === WALK_IN_DRIVE &&
        createJdPage?.venue === "") ||
      createJdPage?.venue === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          venue: {
            mandatory: true,
            patternFail: true,
          },
        };
      });
    } else if (
      !patternFailType(createJdPage?.venue, alphabetsWithTwoThousandChar)
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          venue: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.fromWalkDate === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          fromWalkDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.toWalkDate === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          toWalkDate: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage?.fromTiming === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          fromTiming: {
            mandatory: true,
          },
        };
      });
    }

    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage?.toTiming === null
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          toTiming: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    }
    if (
      createJdPage.job_posting_type === WALK_IN_DRIVE &&
      createJdPage.fromTiming !== null &&
      createJdPage.toTiming !== null &&
      !createJdPage.fromTiming?.isBefore(createJdPage.toTiming)
    ) {
      setErrors((prev) => {
        return {
          ...prev,
          toTiming: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
    if (createJdPage?.country === null) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          country: {
            mandatory: true,
          },
        };
      });
    }

    if (createJdPage?.name === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          name: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (
      !patternFailType(createJdPage?.name, onlyAlphabetsWithMaxFifteenChar)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          name: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (createJdPage?.email === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (!patternFailType(createJdPage?.email, validEmailPattern)) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          email: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.phoneNo !== null &&
      !patternFailType(createJdPage?.phoneNo, mobileNumberPattern)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          phoneNo: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.aboutCompany !== null &&
      !patternFailType(createJdPage?.aboutCompany, alphabetsWithTwoThousandChar)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          jobDescription: {
            patternFail: true,
          },
        };
      });
    }

    if (
      createJdPage.onlineUrl !== null &&
      !patternFailType(createJdPage?.onlineUrl, onlineUrl)
    ) {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          vacancy: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }

  if(saveTemplate){
    if (createJdPage?.templateNameField === "") {
      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          templateNameField: {
            mandatory: true,
            patternFail: false,
          },
        };
      });
    } else if (
        !patternFailType(
          createJdPage?.templateNameField,
          alphanumericSnakeCase
        )
      ) {
          setErrors((prevErrors) => {
            return {
              ...prevErrors,
              templateNameField: {
                mandatory: false,
                patternFail: true,
              },
            };
          });
        }
  }

    setToaster((toast) => {
      return {
        ...toast,
        open: true,
        message: t("CREATE_JD.PLEASE_FILL_THE_FIELDS"),
        status: Status.Error,
      };
    });
  };

  const handleCreateJd = () => {
    if (handleCreateJdFormValidation()) {
      const FormData = handleCreateJdFormData();
      dispatch(createJdThunk(FormData));
      setSaveTemplate(false);
      setCreateJdPage(initialCreateJdState);
    } else {
      handleCreateJdFormErrors();
    }
  };

  const handleCancelCreateJd = () => {
    dispatch(resetCreateJdState());
    setCreateJdPage(initialCreateJdState);
    setErrors(initialErrorJdState);
    navigate(jobsPage);
  };

  const getSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    const allMatch = value.every((skill) =>
      alphabetsNumbersWithDotAndHashAndSpace.test(skill)
    );

    if (allMatch) {
      setCreateJdPage((prev) => {
        return {
          ...prev,
          mustHaveSkill: value,
        };
      });
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: false,
          },
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          mustHaveSkill: {
            mandatory: false,
            patternFail: true,
          },
        };
      });
    }
  };

  const getGoodToHaveSkillsValue = (
    event: SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<string> | undefined
  ) => {
    setCreateJdPage((prev) => {
      return {
        ...prev,
        goodToHaveSkill: value,
      };
    });
  };

  const handleToastClose = () => {
    setToaster((toast) => {
      return {
        ...toast,
        open: false,
        message: "",
      };
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackBar {...toaster} handleClose={handleToastClose} />
      <Box>
        <Paper sx={{ py: 4 }}>
          <Grid container px={3.5} gap={3}>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <DropDownitems
                placeholder={t("CREATE_JD.JOB_POSTING_TYPE")}
                id="job_posting_type"
                value={createJdPage.job_posting_type}
                label={t("CREATE_JD.JOB_POSTING_TYPE")}
                menuItems={MenuItems}
                isMandatory={true}
                handleChange={handleFieldChange}
              />
              <Typography
                fontSize={"var(--font-size-sm)"}
                color={"var(--color-text-grey)"}
                p={1.5}
              >
                <span style={{ color: "var(--color-dark-red)" }}>
                  {t("CREATE_JD.NOTE")}:
                </span>{" "}
                {t("CREATE_JD.PLEASE_SELECT_A_JOB_TYPE")}
              </Typography>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <DropDownitems
                placeholder={t("CREATE_JD.DOMAIN")}
                id="domain"
                value={createJdPage.domain}
                label={t("CREATE_JD.DOMAIN")}
                menuItems={RegionItems}
                handleChange={handleFieldChange}
                isDisabled
              />
            </Grid>
          </Grid>
          <Grid container px={3.5} mt={2} gap={3}>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.JOB_TITLE")}
                required
                id="jobTitleField"
                errorMessage={
                  errors?.jobTitleField?.mandatory ||
                  errors?.jobTitleField?.patternFail
                }
                helperText={
                  errors?.jobTitleField?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_JOB_TITLE")
                    : errors?.jobTitleField?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                value={createJdPage.jobTitleField ?? ""}
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "jobTitleField",
                    true,
                    alphaNumericWithSpaceOnlySpecialCharPattern
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.COMPANY_NAME")}
                id="companyName"
                errorMessage={errors?.companyName?.patternFail}
                helperText={
                  errors?.companyName?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                value={createJdPage.companyName ?? ""}
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "companyName",
                    false,
                    alphaNumericWithSpaceOnlySpecialCharPattern
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <DropDownitems
                placeholder={t("CREATE_JD.STATUS")}
                id="status"
                value={createJdPage.status}
                label={t("CREATE_JD.STATUS")}
                menuItems={StatusItems}
                handleChange={handleFieldChange}
                isDisabled
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.INDUSTRY")}</InputLabel>
                <Select
                  id="industry"
                  value={createJdPage.industry?.industry_name ?? ""}
                  label={t("CREATE_JD.INDUSTRY")}
                  onChange={(e) => {
                    handleSelectChange(
                      "industry",
                      e,
                      industryItemsData,
                      "industry_name"
                    );
                  }}
                >
                  {industryItemsData.map((menu: IIndustryData) => (
                    <MenuItem key={menu.id} value={menu.industry_name}>
                      {menu.industry_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CATEGORY")}</InputLabel>
                <Select
                  id="category"
                  value={createJdPage.category?.category_name ?? ""}
                  label={t("CREATE_JD.CATEGORY")}
                  onChange={(e) => {
                    handleSelectChange(
                      "category",
                      e,
                      categoryItemsData,
                      "category_name",
                      RolesThunkToken
                    );
                  }}
                >
                  {categoryItemsData.map((menu: ICategoryData) => (
                    <MenuItem key={menu.id} value={menu.category_name}>
                      {menu.category_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.ROLES")}</InputLabel>
                <Select
                  id="roles"
                  value={createJdPage.roles?.role_name ?? ""}
                  label={t("CREATE_JD.ROLES")}
                  onChange={(e) => {
                    handleSelectChange("roles", e, rolesItemsData, "role_name");
                  }}
                  disabled={!createJdPage.category}
                >
                  {rolesItemsData.map((menu: IRolesData) => (
                    <MenuItem key={menu.id} value={menu.role_name}>
                      {menu.role_name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.category && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_CATEGORY")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoDatePicker
                value={createJdPage.job_end_date ?? null}
                label={t("CREATE_JD.JOB_END_DATE")}
                id="job_end_date"
                handleDatePickChange={handleDatePickChange}
                minDate={dayjs().add(1, "day")}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
              <AssistoTextFiled
                label={t("CREATE_JD.VACANCY")}
                id="vacancy"
                value={String(createJdPage.vacancy ?? "")}
                errorMessage={errors?.vacancy?.patternFail}
                helperText={
                  errors?.vacancy?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "vacancy",
                    false,
                    onlyNumbersAboveZeroAndMaxFourDigits
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
              <DropDownitems
                placeholder={t("CREATE_JD.NOTICE_PERIOD")}
                id="notice_period"
                value={createJdPage.notice_period}
                label={t("CREATE_JD.NOTICE_PERIOD")}
                menuItems={NoticePeriodItems}
                handleChange={handleFieldChange}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <BusinessCenterRoundedIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.WORK_EXPERIENCE")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={7.2} lg={7.2} md={12} sm={12}>
              <FormControl fullWidth error={errors?.jobSummary?.patternFail}>
              <TextEditor 
                  onChange={(content)=>{
                    handleTextEditor(
                      content,
                      'jobSummary',
                      false,
                      alphabetsWithTwoThousandChar
                    )
                  }} 
                  placeholder={t("CREATE_JD.JOB_SUMMARY")}
                  id="jobSummary"
                  value={createJdPage.jobSummary ?? ""}
                  style={{height:'80px',
                    marginBottom:'50px',
                  }}
                />
                {createJdPage.jobSummary !== "" &&
                  errors?.jobSummary?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid
              item
              xl={3.5}
              lg={3.5}
              md={12}
              sm={12}
              display={"grid"}
              alignItems={"center"}
            >
              <Grid item xs={12} xl={12} sx={{ margin: "6px 0" }}>
                <Grid container alignItems={"center"} gap={2}>
                  <Grid item xs={3} pl={1}>
                    <Typography>
                      {t("CREATE_JD.EXPERIENCE")}
                      <span style={{ marginLeft: "3px", color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      required
                      errorMessage={
                        errors?.fromExpField?.mandatory ||
                        errors?.fromExpField?.patternFail ||
                        errors?.fromExpField?.lessThan
                      }
                      helperText={
                        errors?.fromExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.fromExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : ""
                      }
                      id="fromExpField"
                      label={t("CREATE_JD.FROM")}
                      value={String(createJdPage.fromExpField)}
                      handleChange={(e) => {
                        handleFieldChange(
                          e,
                          "fromExpField",
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                  <Grid item lg={0.25}>
                    <hr />
                  </Grid>
                  <Grid item xs={3.5}>
                    <AssistoTextFiled
                      errorMessage={
                        errors?.toExpField?.mandatory ||
                        errors?.toExpField?.patternFail ||
                        errors?.toExpField?.greaterThan
                      }
                      required
                      helperText={
                        errors?.toExpField?.mandatory
                          ? t("CREATE_JD.PLEASE_ENTER_EXPERIENCE")
                          : errors?.toExpField?.patternFail
                          ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                          : errors.toExpField.greaterThan
                          ? t("CREATE_JD.TO_VALUE_SHOULD_BE_GREATER")
                          : ""
                      }
                      id="toExpField"
                      label={t("CREATE_JD.TO")}
                      value={String(createJdPage.toExpField)}
                      handleChange={(e) => {
                        handleFieldChange(
                          e,
                          "toExpField",
                          true,
                          experienceFieldWithDecimalChar
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xl={10.83} lg={10.83} md={12} sm={12}>
              <FormControl
                fullWidth
                error={errors?.jobDescription?.patternFail}
              >
                <TextEditor 
                  onChange={(content)=>{
                    handleTextEditor(
                      content,
                      'jobDescription',
                      false,
                      alphabetsWithFiveThousandChar
                    )
                  }} 
                  placeholder={t("CREATE_JD.JOB_DESCRIPTION")}
                  id="jobDescription"
                  value={createJdPage.jobDescription ?? ""}
                  style={{height:'80px',
                    marginBottom:'50px',
                  }}
                />
                {createJdPage.jobDescription !== "" &&
                  errors?.jobDescription?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_FIVE_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <AssistantIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.SKILLS_TITLE")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required
                label={t("CREATE_JD.MUST_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                value={createJdPage.mustHaveSkill}
                skillsList={skillsItemData}
                onChange={getSkillsValue}
                error={
                  errors?.mustHaveSkill?.mandatory ||
                  errors?.mustHaveSkill?.patternFail
                }
                helperText={
                  errors?.mustHaveSkill?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_ATLEAST_ONE_MUST_HAVE_SKILL")
                    : errors.mustHaveSkill.patternFail
                    ? t("CREATE_JD.PLEASE_REMOVE_INVALID_DATA")
                    : ""
                }
              />
            </Grid>
            <Grid item xl={7.25} lg={7.25} md={12} sm={12}>
              <AssistoCustomAddInputField
                required={false}
                value={createJdPage.goodToHaveSkill}
                label={t("CREATE_JD.GOOD_TO_HAVE_SKILL")}
                placeholder={t("CREATE_JD.PLEASE_ENTER_SKILL")}
                skillsList={selectedSkillData}
                onChange={getGoodToHaveSkillsValue}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <SchoolIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.EDUCATION")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <FormControl fullWidth error={errors?.certifiedDegree?.mandatory}>
                <InputLabel>
                  {t("CREATE_JD.CERTIFIED_DEGREE_NAME")}
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                  </React.Fragment>
                </InputLabel>
                <Select
                  id="certifiedDegree"
                  value={
                    createJdPage.certifiedDegree?.education_degree_name ?? ""
                  }
                  label={t("CREATE_JD.CERTIFIED_DEGREE_NAME")}
                  onChange={(e) =>
                    handleSelectChange(
                      "certifiedDegree",
                      e,
                      degreeItemsData,
                      "education_degree_name",
                      SpecializationThunk
                    )
                  }
                  required
                >
                  {degreeItemsData.map((menu: IEducationData) => (
                    <MenuItem key={menu.id} value={menu.education_degree_name}>
                      {menu.education_degree_name}
                    </MenuItem>
                  ))}
                </Select>
                {errors?.certifiedDegree?.mandatory && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_CERTIFIED_DEGREE_NAME")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <FormControl fullWidth error={errors?.specialization?.mandatory}>
                <InputLabel>
                  {t("CREATE_JD.SPECIALIZATION")}
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                  </React.Fragment>
                </InputLabel>
                <Select
                  id="specialization"
                  value={createJdPage.specialization?.specialization_name ?? ""}
                  label={t("CREATE_JD.SPECIALIZATION")}
                  onChange={(e) =>
                    handleSelectChange(
                      "specialization",
                      e,
                      specializationItemsData,
                      "specialization_name"
                    )
                  }
                  required
                  disabled={!createJdPage.certifiedDegree}
                >
                  {specializationItemsData.map((menu: ISpecializationData) => (
                    <MenuItem key={menu.id} value={menu.specialization_name}>
                      {menu.specialization_name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.certifiedDegree && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_CERTIFIED_DEGREE")}*
                  </FormHelperText>
                )}
                {errors?.specialization.mandatory &&
                createJdPage.certifiedDegree !== null &&
                createJdPage.specialization === null ? (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_SPECIALIZATION")}*
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <PaidIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.SALARY")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={6} sm={12}>
              <AssistoTextFiled
                errorMessage={errors?.minSalary?.patternFail}
                required={false}
                helperText={
                  errors?.minSalary?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                id="minSalary"
                label={t("CREATE_JD.MINIMUM_SALARY")}
                value={String(
                  createJdPage.minSalary !== null ? createJdPage.minSalary : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "minSalary", false, digitsOnly);
                }}
              />
            </Grid>
            <Grid item xs={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                errorMessage={
                  errors?.maxSalary?.patternFail || errors?.maxSalary?.maxSalary
                }
                required={false}
                helperText={
                  errors?.maxSalary?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : errors?.maxSalary.maxSalary
                    ? t("CREATE_JD.MAX_SALARY_SHOULD_BE_GREATER")
                    : ""
                }
                id="maxSalary"
                label={t("CREATE_JD.MAXIMUM_SALARY")}
                value={String(
                  createJdPage.maxSalary !== null ? createJdPage.maxSalary : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "maxSalary", false, digitsOnly);
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth error={errors?.currency?.mandatory}>
                <InputLabel>{t("CREATE_JD.CURRENCY")}</InputLabel>
                <Select
                  id="currency"
                  value={createJdPage.currency?.cc ?? ""}
                  label={t("CREATE_JD.CURRENCY")}
                  onChange={(e) => {
                    handleSelectChange("currency", e, currencyItemData, "cc");
                  }}
                  inputProps={{
                    "data-testid": "currency",
                  }}
                  disabled={
                    createJdPage.minSalary === "" &&
                    createJdPage.maxSalary === ""
                  }
                >
                  {currencyItemData.map((menu: ICurrencyResponse) => (
                    <MenuItem key={menu.cc} value={menu.cc}>
                      {menu.cc}
                    </MenuItem>
                  ))}
                </Select>
                {(!createJdPage?.minSalary ||
                  createJdPage?.minSalary === "") && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_MINIMUM_SALARY")}*
                  </FormHelperText>
                )}
                {errors?.currency?.mandatory ? (
                  <FormHelperText>
                    {t("CREATE_JD.CURRENCY_IS_MANDATORY")}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <FmdGoodIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.JOB_LOCATION")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl
                fullWidth
                error={
                  (createJdPage.country === null ||
                    createJdPage.country?.name === "") &&
                  errors?.country?.mandatory
                }
              >
                <InputLabel>
                  {t("CREATE_JD.COUNTRY")}
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={"var(--color-dark-red)"}
                    >
                      &nbsp;*
                    </Typography>
                  </React.Fragment>
                </InputLabel>
                <Select
                  id="country"
                  value={createJdPage.country?.name ?? ""}
                  label={t("CREATE_JD.COUNTRY")}
                  onChange={(e) => {
                    handleSelectChange(
                      "country",
                      e,
                      countryItems,
                      "name",
                      StateLocationToken
                    );
                  }}
                  inputProps={{
                    "data-testid": "country",
                  }}
                  required
                >
                  {countryItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {(createJdPage.country === null ||
                  createJdPage.country?.name === "") &&
                  errors?.country?.mandatory && (
                    <FormHelperText>
                      {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.STATE")}</InputLabel>
                <Select
                  id="state"
                  value={createJdPage.state?.name ?? ""}
                  label={t("CREATE_JD.STATE")}
                  onChange={(e) => {
                    handleSelectChange(
                      "state",
                      e,
                      stateItems,
                      "name",
                      CityLocationToken
                    );
                  }}
                  disabled={!createJdPage.country}
                >
                  {stateItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_FIRST_COUNTRY")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <FormControl fullWidth>
                <InputLabel>{t("CREATE_JD.CITY")}</InputLabel>
                <Select
                  id="city"
                  value={createJdPage.city?.name ?? ""}
                  label={t("CREATE_JD.CITY")}
                  onChange={(e) => {
                    handleSelectChange("city", e, cityItems, "name");
                  }}
                  disabled={!createJdPage.country || !createJdPage.state}
                >
                  {cityItems.map((menu: ILocationInfoResponse) => (
                    <MenuItem key={menu.id} value={menu.name}>
                      {menu.name}
                    </MenuItem>
                  ))}
                </Select>
                {!createJdPage.country && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_COUNTRY_&_STATE")}*
                  </FormHelperText>
                )}
                {createJdPage.country && !createJdPage.state && (
                  <FormHelperText>
                    {t("CREATE_JD.PLEASE_SELECT_STATE")}*
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {createJdPage.job_posting_type === CONTRACT && (
              <>
                <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                  <Stack direction={"row"} gap={1}>
                    <DrawRoundedIcon />
                    <Typography
                      fontSize={"var(--font-size-18)"}
                      fontWeight={"var(--font-weight-500)"}
                    >
                      {t("CREATE_JD.CONTRACT")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoDatePicker
                    required
                    id="joiningDate"
                    value={createJdPage.joiningDate ?? null}
                    label={t("CREATE_JD.EARLY_JOINING_DATE")}
                    handleDatePickChange={handleDatePickChange}
                    minDate={dayjs().add(1, "day")}
                    error={
                      createJdPage?.joiningDate === null &&
                      errors?.joiningDate?.mandatory
                    }
                    helperText={
                      createJdPage?.joiningDate === null &&
                      errors?.joiningDate?.mandatory
                        ? t("CREATE_JD.EARLY_JOINING_DATE_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
                  <AssistoTextFiled
                    errorMessage={
                      errors?.contractTimeperiod?.mandatory ||
                      errors?.contractTimeperiod?.patternFail
                    }
                    required
                    helperText={
                      errors?.contractTimeperiod?.mandatory
                        ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                        : errors?.contractTimeperiod?.patternFail
                        ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                        : ""
                    }
                    id="contractTimeperiod"
                    label={t("CREATE_JD.CONTRACT_TIME_PERIOD")}
                    value={String(
                      createJdPage.contractTimeperiod !== null
                        ? createJdPage.contractTimeperiod
                        : ""
                    )}
                    handleChange={(e) => {
                      handleFieldChange(
                        e,
                        "contractTimeperiod",
                        true,
                        alphanumericWithSpaceAndDotChar
                      );
                    }}
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12} pt={1}>
                  <DropDownitems
                    placeholder={t("CREATE_JD.BILLING_RATE")}
                    id="billingRate"
                    value={createJdPage.billingRate ?? ""}
                    label={t("CREATE_JD.BILLING_RATE")}
                    menuItems={BillingRateItems}
                    isMandatory={true}
                    handleChange={handleFieldChange}
                  />
                </Grid>
              </>
            )}
            {createJdPage.job_posting_type === WALK_IN_DRIVE && (
              <>
                <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
                  <Stack direction={"row"} gap={1}>
                    <SaveAsIcon />
                    <Typography
                      fontSize={"var(--font-size-18)"}
                      fontWeight={"var(--font-weight-500)"}
                    >
                      {t("CREATE_JD.WALK_IN_DETAILS")}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xl={7.2} lg={7.2} md={7.2} sm={12}>
                  <FormControl
                    fullWidth
                    error={
                      (createJdPage?.venue === "" ||
                        createJdPage.venue === null) &&
                      errors?.venue?.mandatory &&
                      errors?.venue?.patternFail
                    }
                  >
                    <TextField
                      fullWidth
                      label={
                        <React.Fragment>
                          {t("CREATE_JD.VENUE")}
                          <Typography
                            component="span"
                            variant="body2"
                            style={{ color: "var(--color-dark-red)" }}
                          >
                            &nbsp;*
                          </Typography>
                        </React.Fragment>
                      }
                      multiline
                      id="venue"
                      value={createJdPage.venue ?? ""}
                      rows={3}
                      inputProps={{
                        "data-testid": "venue",
                      }}
                      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        handleFieldChange(
                          e,
                          "venue",
                          true,
                          alphabetsWithTwoThousandChar
                        )
                      }
                      error={
                        (createJdPage?.venue === "" ||
                          createJdPage.venue === null) &&
                        errors?.venue?.mandatory &&
                        errors?.venue?.patternFail
                      }
                    />
                    {errors?.venue?.mandatory === null && (
                      <FormHelperText error>
                        {t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")}
                      </FormHelperText>
                    )}
                    {createJdPage.venue !== "" &&
                      errors?.venue?.patternFail && (
                        <FormHelperText>
                          {t(
                            "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                          )}
                        </FormHelperText>
                      )}
                    {createJdPage.venue === "" && errors?.venue?.mandatory && (
                      <FormHelperText>
                        {t(
                          "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                        )}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoDatePicker
                    required
                    value={createJdPage.fromWalkDate ?? null}
                    label={t("CREATE_JD.WALK_IN_DATE_FROM")}
                    id="fromWalkDate"
                    handleDatePickChange={handleDatePickChange}
                    minDate={dayjs().add(1, "day")}
                    error={
                      createJdPage?.fromWalkDate === null &&
                      errors?.fromWalkDate?.mandatory
                    }
                    helperText={
                      createJdPage?.fromWalkDate === null &&
                      errors?.fromWalkDate?.mandatory
                        ? t("CREATE_JD.WALK_IN_DATE_IS_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoDatePicker
                    required
                    value={createJdPage.toWalkDate ?? null}
                    id="toWalkDate"
                    label={t("CREATE_JD.WALK_IN_DATE_TO")}
                    handleDatePickChange={handleDatePickChange}
                    minDate={dayjs().add(2, "day")}
                    error={
                      createJdPage?.toWalkDate === null &&
                      errors?.toWalkDate?.mandatory
                    }
                    helperText={
                      createJdPage?.toWalkDate === null &&
                      errors?.fromWalkDate?.mandatory
                        ? t("CREATE_JD.WALK_IN_DATE_IS_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoTimePicker
                    required
                    label={t("CREATE_JD.FROM_TIMING")}
                    onTimePickChange={onTimePickChange}
                    id="fromTiming"
                    value={createJdPage.fromTiming ?? null}
                    error={
                      createJdPage.fromTiming === null &&
                      errors?.fromTiming?.mandatory
                    }
                    helperText={
                      createJdPage?.fromTiming === null &&
                      errors?.fromTiming?.mandatory
                        ? t("CREATE_JD.FROM_TIMING_IS_MANDATORY")
                        : ""
                    }
                  />
                </Grid>
                <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
                  <AssistoTimePicker
                    required
                    label={t("CREATE_JD.TO_TIMING")}
                    id="toTiming"
                    onTimePickChange={onTimePickChange}
                    value={createJdPage.toTiming ?? null}
                    error={
                      errors?.toTiming?.mandatory ||
                      errors?.toTiming?.patternFail
                    }
                    helperText={
                      errors?.toTiming?.mandatory
                        ? t("CREATE_JD.TO_TIMING_IS_MANDATORY")
                        : errors?.toTiming?.patternFail
                        ? t("CREATE_JD.TO_TIMING_SHOULD_BE_GREATER")
                        : ""
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} mt={2}>
              <Stack direction={"row"} gap={1}>
                <ContactsIcon />
                <Typography
                  fontSize={"var(--font-size-18)"}
                  fontWeight={"var(--font-weight-500)"}
                >
                  {t("CREATE_JD.CONTACT")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.NAME")}
                required
                id="name"
                value={createJdPage.name ?? ""}
                errorMessage={
                  errors?.name?.mandatory || errors?.name?.patternFail
                }
                helperText={
                  errors?.name?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_NAME")
                    : errors?.name?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(
                    e,
                    "name",
                    true,
                    onlyAlphabetsWithMaxFifteenChar
                  );
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                errorMessage={errors?.phoneNo?.patternFail}
                required={false}
                helperText={
                  errors?.phoneNo?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                id="phoneNo"
                label={t("CREATE_JD.PHONE_NUMBER")}
                value={String(
                  createJdPage.phoneNo !== null ? createJdPage.phoneNo : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "phoneNo", false, mobileNumberPattern);
                }}
              />
            </Grid>
            <Grid item xl={3.5} lg={3.5} md={3.5} sm={12}>
              <AssistoTextFiled
                label={t("CREATE_JD.EMAIL")}
                required
                id="email"
                value={createJdPage.email ?? ""}
                errorMessage={
                  errors?.email?.mandatory || errors?.email?.patternFail
                }
                helperText={
                  errors?.email?.mandatory
                    ? t("CREATE_JD.PLEASE_ENTER_EMAIL")
                    : errors?.email?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                handleChange={(e) => {
                  handleFieldChange(e, "email", true, validEmailPattern);
                }}
              />
            </Grid>
            <Grid item xl={7.2} lg={7.2} md={7.2} sm={12}>
              <FormControl fullWidth error={errors?.aboutCompany?.patternFail}>
                <TextField
                  fullWidth
                  label={t("CREATE_JD.ABOUT_COMPANY")}
                  multiline
                  id="aboutCompany"
                  value={createJdPage.aboutCompany ?? ""}
                  rows={3}
                  inputProps={{
                    "data-testid": "aboutCompany",
                  }}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    handleFieldChange(
                      e,
                      "aboutCompany",
                      false,
                      alphabetsWithTwoThousandChar
                    )
                  }
                />
                {createJdPage.aboutCompany !== "" &&
                  errors?.aboutCompany?.patternFail && (
                    <FormHelperText>
                      {t(
                        "CREATE_JD.PLEASE_ENTER_MINIMUM_TWO_THOUSAND_CHARACTER"
                      )}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>
            <Grid
              item
              xl={3.5}
              lg={3.5}
              md={6}
              sm={12}
              alignItems={"center"}
              display={"grid"}
            >
              <AssistoTextFiled
                errorMessage={errors?.onlineUrl?.patternFail}
                required={false}
                helperText={
                  errors?.onlineUrl?.patternFail
                    ? t("CREATE_JD.PLEASE_ENTER_VALID_VALUE")
                    : ""
                }
                id="onlineUrl"
                label={t("CREATE_JD.APPLY_ONLINE_URL")}
                value={String(
                  createJdPage.onlineUrl !== null ? createJdPage.onlineUrl : ""
                )}
                handleChange={(e) => {
                  handleFieldChange(e, "onlineUrl", true, onlineUrl);
                }}
              />
            </Grid>
            <Stack direction={"row"} gap={2} mt={2}>
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name="Save Template"
                handleBtnClick={() => {
                  setSaveTemplate(true);
                }}
              />
              <AssistoButton
                buttonVarient={ButtonVarient.Contained}
                name="Save"
                handleBtnClick={handleCreateJd}
              />
              <AssistoButton
                buttonVarient={ButtonVarient.Outlined}
                name="Cancel"
                handleBtnClick={handleCancelCreateJd}
              />
            </Stack>

            <AssistoModal
              open={saveTemplate}
              handleClose={() => {
                setSaveTemplate(false);
              }}
              isCloseButtonNeeded={true}
              title={t("CREATE_JD.DO_YOU_WANT_TO_SAVE_TEMPLATE?")}
              style={saveTemplateModalStyle}
            >
              <Grid marginY={"75px"} container justifyContent={"center"} alignItems={"center"}>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <AssistoTextFiled
                    label={t("CREATE_JD.SELECT_TEMPLATE")}
                    required
                    id="templateNameField"
                    errorMessage={
                      errors?.templateNameField?.mandatory ||
                      errors?.templateNameField?.patternFail
                    }
                    helperText={
                      errors?.templateNameField?.mandatory
                        ? t("CREATE_JD.PLEASE_ENTER_TEMPLATE_NAME")
                        : errors?.templateNameField?.patternFail
                        ? t("CREATE_JD.SNAKE_CASE_NAMING_CONVENTION_ONLY")
                        : ""
                    }
                    value={createJdPage.templateNameField.replace(/\s+/g, '_').toLowerCase() ?? "" }
                    handleChange={(e) => {
                      handleFieldChange(
                        e,
                        "templateNameField",
                        true,
                        alphanumericSnakeCase
                      );
                    }}
                    isDisabled={!saveTemplate}
                  />
                  <Typography
                    fontSize={"var(--font-size-sm)"}
                    color={"var(--color-text-grey)"}
                    p={1.5}
                  >
                    <span style={{ color: "var(--color-dark-red)" }}>
                      {t("CREATE_JD.NOTE")}:
                    </span>{" "}
                    {t("CREATE_JD.TEMPLATES_AUTO_FILL_ALL_DATA")}
                  </Typography>
                </Grid>
              </Grid>
                  <Stack justifyContent={"center"}  direction={"row"} gap={2}>
                  <AssistoButton
                    buttonVarient={ButtonVarient.Contained}
                    name="Save"
                    handleBtnClick={handleCreateJd}
                  />
                  <AssistoButton
                    buttonVarient={ButtonVarient.Outlined}
                    name="Cancel"
                    handleBtnClick={()=>{setSaveTemplate(false)
                    }}
                  />
                </Stack>
            </AssistoModal>
          </Grid>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
export default JobDescription;
